gsap.registerPlugin(ScrollTrigger);
var match_Media = gsap.matchMedia();
var paddingDsk = parseFloat(
  getComputedStyle(document.documentElement).getPropertyValue("--padding-large")
);
var paddingDskSmall = parseFloat(
  getComputedStyle(document.documentElement).getPropertyValue(
    "--padding-medium"
  )
);
var isRTL = $("html").attr("dir") == "rtl" ? true : false,
  winWidth = $(window).width(),
  winHeight = $(window).height(),
  topPos = 0,
  bottomPos = 0;
var assetsURL = isRTL ? ".." : ".";
var bannerPrev = `<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="${assetsURL}/assets/img/icon/arrow-right-slider.svg" alt="left"class="svg js-tosvg"/></button>`;
var bannerNext = `<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="${assetsURL}/assets/img/icon/arrow-left-slider.svg" alt="left"class="svg js-tosvg"/</button>`;

$(function () {
  browserDetect();
});

$(window).on("load", function () {
  // Do after Page ready
  domOnLoad();
  ChangeToSvg();
  scrollTop();
  editorialBlockAnimation();
  timelineSectionScroll();
  animKeyFact();
  faqQsAndAnsIds();
  calculateExpandWidth();
  milestone();
  drawerSlider();
  drawer();
  initSelect2(".js-select2");
  intlTelInputInit();
  messageCharacterCount();
  initValidation(".js-contact-form");
  searchAnim();
  videoOnHover();
  clickVideoPopup();
  videoPlayerInit();
  fileInputClick();
  clearFileInput();
  sectionAnim();
});

function domOnLoad() {
  document.querySelector("body").classList.add("loaded");
  dataSrc();
  slickSliderWithCounter();
  testimonialSlider();
  partnerSlider();
  managementSlider();
  isotopes();
  datePickerInit();
}
function datePickerInit() {
  if ($('.js-datepicker').length > 0) {
    $('.js-datepicker').each(function () {
      $(this).datepicker({
        container: '.datepiker-conatiner',
        format: 'M d, yyyy',
        todayHighlight: true,
        // autoClose: true,
        // clearBtn: true
        templates: {
          leftArrow: `<img src="${assetsURL}/assets/img/icon/arrow-green.png" alt="left"/>`,
          rightArrow: `<img src="${assetsURL}/assets/img/icon/arrow-green.png" alt="left"/>`
        },

      });
      $(this).on('show', function (e) {
        $('.datepicker-days thead tr .dow').each(function () {
          var originalText = $(this).text();
          var firstChar = originalText.charAt(0);
          $(this).text(firstChar);
        });
      });
    });

    $('.js-datepicker').hide();
    $('#startDate').on('click , change', function () {
      $('#startDate').datepicker('hide');
      $('#endDate').datepicker('show');
    })
    $('.input-radio').on('change', function () {
      let customDatepicker = $(this).val();
      if (customDatepicker === 'custom-datepicker') {
        $('.js-datepicker').show();
        $('.datepiker-conatiner').show();
        $('#endDate').datepicker('show');
        $('#endDate').data('datepicker').hide = function () { };
      } else {
        $('.datepiker-conatiner').hide();
        $('.js-datepicker').hide();
        $('#endDate').datepicker("clearDates");
        $('#startDate').datepicker("clearDates");
      }

    })
  }
}
$(window).on("orientationchange", function () {
  location.reload();
});
function browserDetect() {
  navigator.sayswho = (function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join("").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  })();
  $("body").addClass(navigator.sayswho);
}

// Change Image to Svg
function ChangeToSvg() {
  $("img.js-tosvg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    $.get(
      imgURL,
      function (data) {
        var $svg = $(data).find("svg");
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " insvg");
        }
        $svg = $svg.removeAttr("xmlns:a");
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
}

// get mouse position on viewport
var mouse = { x: 0, y: 0 };
document.addEventListener("mousemove", function (e) {
  if (winWidth > 767) {
    mouse.x = e.clientX;
    mouse.y = e.clientY;
    mouseText();
  }
});

function mouseText() {
  // track .c-mouseTracker element to mouse position
  var mouseTracker = document.querySelector(".c-mouseTracker");
  if (mouseTracker) {
    mouseTracker.style.transform =
      "translate(" + mouse.x + "px, " + mouse.y + "px)";
  }
}

// set isRTL based on HTML dir attribute
var isRTL = $("html").attr("dir") == "rtl" ? true : false;

$(document).ready(function () {
  $(".menu-link").click(function () {
    $(".menu-link").removeClass("active");
    $(this).addClass("active");
  });
});
$(document).ready(function () {
  $(".menuIcon").click(function () {
    // Toggle the "dark-mode" class on the body element
    $("body").toggleClass("menu--open");
  });
});

// var video_carousel = $('.js-video-carousel').slick({
// 	rtl: isRTL,
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// 	arrows: false,
// 	infinite: false,
// 	speed: 500,
// 	fade: true,
// 	cssEase: 'linear',
// 	dots: true,
// 	// custom dots
// 	customPaging: function (slider, i) {
// 		return '<span class="dot"><img src=""></span>';
// 	},
// });

function clickVideoPopup() {
  $("body")
    .on("click", ".js-videoPopup", function () {
      videoPopup($(this));
      separatePopup();
    })
    .on("click touchstart", ".parent-cl", function (e) {
      $(this).parents(".video-box").toggleClass("play-video");
      var etarget = $(e.target);
      if (winWidth < 1300) {
        if (etarget.parents(".video-box").hasClass("play-video")) {
          etarget[0].play();
        } else {
          etarget[0].pause();
        }
      }
    });
}

function videoPopup(target) {
  var $target = $(target);
  var videoUrl;
  var vidPlayer = null;
  if (winWidth < 768) {
    videoUrl = $target.data("mobile-url");
  } else {
    videoUrl = $target.data("desktop-url");
  }
  var videoClass = $target.data("video-class");
  var videoWidth = $target.data("width");
  var videoHeight = $target.data("height");
  var videoType = $target.data("video-type");
  var videoPoster = $target.data("video-poster") || null;
  var techOrder = ["html5", "youtube", "wistia"];
  var $content =
    '<div class="popup-video op-0"><video id="lightboxVideo" width="' +
    videoWidth +
    '" height="' +
    videoHeight +
    '" preload="auto" controls autoplay class="video-js vjs-layout-large" data-setup="{}"><source src="' +
    videoUrl +
    '" type="video/mp4" /><p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">supports HTML5 video</a></p></video></div>';
  $.fancybox.open({
    type: "html",
    content: $content,
    beforeShow: function () {
      $("body").addClass("is--videopopup");
      $(".fancybox-container").addClass(videoClass);
    },
    afterShow: function () {
      vidPlayer = videojs("lightboxVideo", function () {
        techOrder;
      });
      vidPlayer.src({
        type:
          videoType === "youtube"
            ? "video/youtube"
            : videoType === "wistia"
              ? "video/wistia"
              : "video/mp4",
        src:
          videoType === "youtube"
            ? "https://www.youtube.com/watch?v=" + videoUrl
            : videoType === "wistia"
              ? "http://fast.wistia.com/embed/iframe/" + videoUrl
              : videoUrl,
      });
      if (videoPoster) vidPlayer.poster(videoPoster);
      vidPlayer.on("ready", function () {
        vidPlayer.play();
      });
      $(".popup-video").animate(
        {
          opacity: "1",
        },
        500
      );
    },
    beforeClose: function () {
      $("body").removeClass("is--videopopup");
      videojs("lightboxVideo").dispose();
    },
  });
}

function dataSrc() {
  if (winWidth < 768) {
    $("[data-mobile-src]").each(function () {
      var thisSrc = $(this).attr("data-mobile-src");
      $(this).attr("src", thisSrc);
    });
    $("[data-mobile-poster]").each(function () {
      var thisSrc = $(this).attr("data-mobile-poster");
      $(this).attr("poster", thisSrc);
    });
  } else {
    $("[data-desktop-src]").each(function () {
      var thisSrc = $(this).attr("data-desktop-src");
      $(this).attr("src", thisSrc);
    });
    $("[data-desktop-poster]").each(function () {
      var thisSrc = $(this).attr("data-desktop-poster");
      $(this).attr("poster", thisSrc);
    });
  }
}

function videoPlayerInit() {
  $(".my-video-js").each(function (index) {
    var thisId = $(this).attr("id");
    $(this).addClass("video-js");
    if (!thisId == "") {
      thisId = "video-id-" + index;
      $(this).attr("id", thisId);
    }
    var player = videojs(thisId);
  });
}
// Scroll timeline block animation gsap

function timelineSectionScroll() {
  if ($(".js-sec-timeline").length) {
    match_Media.add("(min-width: 768px)", () => {
      let container = document.querySelector(".js-timeline-block"),
        sideBar = document.querySelector(".js-timeline-sidebar"),
        links = gsap.utils.toArray(".js-timeline-sidebar-year"),
        boxes = gsap.utils.toArray(".js-timeline-block-wrapper"),
        boxRatios = [],
        getTotalMovement = () =>
          container.offsetWidth - window.innerWidth + sideBar.offsetWidth;
      links.forEach((year, index) => {
        $(year).attr("data-value", index);
      });
      boxes.forEach((block, index) => {
        $(block).attr("data-value", index);
      });

      let scrollTween = gsap.to(container, {
        x: () => (isRTL ? getTotalMovement() : -getTotalMovement()),
        ease: "none",
        scrollTrigger: {
          trigger: ".js-sec-timeline",
          pin: true,
          scrub: 1,
          start: "top top",
          end: () => `+=${container.offsetWidth}`,
          invalidateOnRefresh: true,
          onRefresh(self) {
            let cumulative = 0,
              total = getTotalMovement();
            boxes.forEach((box, i) => {
              boxRatios[i] = Math.min(1, cumulative / total);
              cumulative += box.offsetWidth;
            });
          },
          onUpdate(self) {
            const progress = self.progress;

            for (let i = 0; i < boxRatios.length; i++) {
              if (progress >= boxRatios[i] && progress < boxRatios[i + 1]) {
                links.forEach((year, index) => {
                  year.classList.remove("active");
                  const blockAttribute = boxes[i].getAttribute("data-value");
                  const yearAttribute = year.getAttribute("data-value");

                  if (blockAttribute === yearAttribute) {
                    year.classList.add("active");
                  }
                });

                break;
              }

              if (progress >= boxRatios[i] && !boxRatios[i + 1]) {
                links.forEach((year, index) => {
                  year.classList.remove("active");
                  const blockAttribute =
                    boxes[boxes.length - 1].getAttribute("data-value");
                  const yearAttribute = year.getAttribute("data-value");

                  if (blockAttribute === yearAttribute) {
                    year.classList.add("active");
                  }
                });
                break;
              }
            }
          },
        },
      });
      let st = scrollTween.scrollTrigger;
      links.forEach((link, i) => {
        link.addEventListener("click", (e) => {
          const skipHasClass = $(e.target).hasClass("js-skip-year");
          if (skipHasClass) {
            $("html,body").animate(
              {
                scrollTop: st.end + window.innerHeight,
              },
              1000
            );
          } else {
            $("html,body").animate(
              {
                scrollTop: st.start + boxRatios[i] * (st.end - st.start) + 1,
              },
              1000
            );
          }
        });
      });
      return () => {
        // optional
        // custom cleanup code here (runs when it STOPS matching)
        gsap.set(container, {
          clearProps: "all",
        });
      };
    });
  }
}

function scrollTop() {
  $(".header-listing a").click(function () {
    var targetSection = $(this).attr("href");
    $("html,body").animate(
      {
        scrollTop: $(targetSection).offset().top,
      },
      "slow"
    );
  });
  var lastScrollTop = 0;
  $(window).scroll(function () {
    var st = $(this).scrollTop();
    if (st > lastScrollTop) {
      $("body").removeClass("scrollTop");
      $("body").addClass("bodyScrolled");
    } else {
      $("body").addClass("scrollTop");
      $("body").removeClass("bodyScrolled");
    }
    if (st === 0) {
      $("body").removeClass("scrollTop");
      $("body").removeClass("bodyScrolled");
    }

    lastScrollTop = st;
  });
}

function videoOnHover() {
  $(document).on("mouseover", ".js-hoverable-video", function () {
    if (winWidth > 1220) {
      const childElement = $(this).find('.my-video-js');
      const isWistiaVideo = childElement.hasClass('js-wistia-hover')
      if (isWistiaVideo) {
        const wistiaVideo = $(this).find('.video-js').attr("id");
        const wistiaPlayer = videojs(wistiaVideo);
        wistiaPlayer.play();
        $('.vjs-poster').hide()
      } else {
        var videoElements = $(this).find(".vjs-poster");
        var video = videoElements.is("video")
          ? videoElements.get(0)
          : videoElements.siblings("video").get(0);
        video.play();
      }
    }
  });

  $(document).on("mouseleave", ".js-hoverable-video", function () {
    const childElement = $(this).find('.my-video-js');
    const isWistiaVideo = childElement.hasClass('js-wistia-hover')
    if (isWistiaVideo) {
      const wistiaVideo = $(this).find('.video-js').attr("id");
      // console.log(video, '---dsad');
      const wistiaPlayer = videojs(wistiaVideo);
      wistiaPlayer.pause();
      $('.vjs-poster').show()
      // player.currentTime(10);
    } else {
      var videoElements1 = $(this).find("video");
      videoElements1.get(0).load();

    }
  });

  $(document).on("click", ".play-btn-js", function () {
    $(this).hide();
    var video = $(this).siblings(".my-video-js").children("video").get(0);
    video.play();
  });

  $(document).on("click touchstart", ".vjs-poster", function () {
    $(this).parent().parent().find("> button").hide();
  });

  $(document).on("click touchstart", ".vjs-iframe-blocker", function () {
    $(this).parent().parent().parent().find("> button").hide();
    var video = $(this).parent().parent().attr("id");
    var player = videojs(video);
    player.play();
  });

  $(document).on("click", ".play-youtube-btn-js", function () {
    $(this).hide();
    var video = $(this).siblings(".my-video-js").attr("id");
    var player = videojs(video);
    player.play();
  });

  $(document).on("click", ".play-wistia-btn-js", function () {
    $(this).hide();
    var video = $(this).siblings().attr("id");
    var player = videojs(video);
    player.play();
  });

  var allVideos = document.querySelectorAll(".my-video-js");

  allVideos.forEach((video) => {
    var player = videojs(video);
    player.on("play", function () {
      if (video.classList.value.includes("js-bg-video")) {
        return;
      }
      const filterVid = Array.from(allVideos).filter((vid) => vid !== video);
      filterVid.forEach((video) => {
        if (video.classList.value.includes("js-bg-video")) {
          return;
        }
        var player1 = videojs(video);
        player1.pause();
      });
    });
  });
}

function separatePopup() {
  var allVideos = document.querySelectorAll(".my-video-js");
  allVideos.forEach((video) => {
    if (video.classList.value.includes("js-bg-video")) {
      return;
    }
    var player = videojs(video);
    player.pause();
  });
}

// Scroll timeline block animation gsap

function animKeyFact() {
  if ($(".js-kf-trigger").length) {
    const triggerAnim = $(".js-kf-trigger");
    const animItems = $(".js-kf-items");
    const containPadding = winWidth > 1600 ? paddingDsk : paddingDskSmall;
    const translateX = isRTL
      ? animItems.width() - winWidth + containPadding * 2
      : -animItems.width() + winWidth - containPadding * 2;
    let tl_kf;
    match_Media.add("(min-width: 768px)", () => {
      tl_kf = gsap.timeline({
        scrollTrigger: {
          trigger: triggerAnim,
          pin: true,
          scrub: 1,
          start: "top top",
          end: () => `+=${animItems.width()}`,
        },
      });
      tl_kf.to(animItems, {
        x: translateX,
        ease: "ease-in-out",
      });
    });
  }
}

//Pause Viedo on open accordion
function pauseAccordionVideo($videoElements, $clikedAccordion) {
  if ($videoElements && $videoElements.length > 0) {
    $videoElements.each((index, elem) => {
      var player1 = videojs(elem);
      if (!$clikedAccordion.hasClass("expand")) {
        player1.pause();
      }
    });
  }
}

// MileStone Horizontal accordion
function milestone() {
  if ($(".milestone-box").length) {
    calculateExpandWidth();

    $(".milestone-box").click(function () {
      pauseAccordionVideo($(".js-video-milestone"), $(this));
      $(".milestone-box").removeClass("expand").addClass("small");
      $(this).removeClass("small").addClass("expand");
    });

    $(".prev-button").click(function (event) {
      event.stopPropagation();
      var $currentAccordion = $(this).closest(".milestone-box");
      var $prevAccordion = $currentAccordion.prev();
      if ($prevAccordion.length > 0) {
        $currentAccordion.removeClass("expand").addClass("small");
        $prevAccordion.removeClass("small").addClass("expand");
      }
      pauseAccordionVideo($(".js-video-milestone"), $currentAccordion);
    });

    $(".next-button").click(function (event) {
      event.stopPropagation();
      var $currentAccordion = $(this).closest(".milestone-box");
      var $nextAccordion = $currentAccordion.next();
      if ($nextAccordion.length > 0) {
        $currentAccordion.removeClass("expand").addClass("small");
        $nextAccordion.removeClass("small").addClass("expand");
      }
      pauseAccordionVideo($(".js-video-milestone"), $currentAccordion);
    });
  }
}

function calculateExpandWidth() {
  if (winWidth > 991) {
    var totalSmallDivWidth = 0;
    $(".milestone-box").each(function () {
      totalSmallDivWidth += $(this).width();
    });

    var containerWidth = $(".milestone-wrapper").width();
    var remainingWidth = containerWidth - totalSmallDivWidth;
    document.documentElement.style.setProperty(
      "--width-milestone-wrapper",
      `${remainingWidth}px`
    );
  }
}

// Faq Section
function faqQsAndAnsIds() {
  const faqQs = $(".js-faq-qs");
  const faqAns = $(".js-faq-ans");
  const secFaqQs = $(".js-sec-faq-qs");
  const secFaqAns = $(".js-sec-faq-ans");

  if (faqQs && faqQs.length > 0 && faqAns && faqAns.length > 0) {
    if (window.innerWidth > 991) {
      faqQs.each((index, qs) => {
        $(qs).attr("data-value", index);
      });

      faqAns.each((index, ans) => {
        $(ans).attr("data-value", index);
      });

      const heightSecFaqQs = secFaqQs.height();
      secFaqAns.css("maxHeight", `${heightSecFaqQs}px`);

      faqQs.each((_, qs) => {
        qs.addEventListener("click", (e) => {
          faqQs.each((_, qs) => qs.classList.remove("active"));
          qs.classList.add("active");

          faqAns.each((j, ans) => {
            const blockValue = $(ans).data("value");
            const yearValue = $(qs).data("value");

            const videoElements = ans.querySelectorAll(".my-video-js");

            if (videoElements && videoElements.length > 0) {
              videoElements.forEach((elem) => {
                var player1 = videojs(elem);
                player1.pause();
              });
            }

            if (blockValue === yearValue) {
              faqAns.each((_, ans) => ans.classList.remove("active"));
              ans.classList.add("active");
            }
          });
        });
      });
    } else {
      const mobileQs = $(".js-mobile-qs");
      if (mobileQs && mobileQs.length > 0) {
        mobileQs.each((j, qs) => {
          $(qs).on("click", (e) => {
            const associatedAns = $(qs).closest(".js-faq-ans");

            const videoElements = $(associatedAns).find(".my-video-js");

            if (videoElements && videoElements.length > 0) {
              videoElements.each(function () {
                var player1 = videojs(this);
                player1.pause();
              });
            }

            $(faqAns).not(associatedAns).removeClass("active");
            associatedAns.toggleClass("active");
          });
        });
      }
    }
  }
}

//Slide Count Function
function slideCount(slideElements, i, key) {
  var totalSlides = slideElements.slick("getSlick").slideCount;
  var currentSlide = slideElements.slick("slickCurrentSlide");

  updateSlideCount(currentSlide, totalSlides);

  slideElements.on("afterChange", function (event, slick, currentSlide) {
    updateSlideCount(currentSlide, totalSlides);
  });

  function updateSlideCount(current, total) {
    var currentSlideText = current + 1 < 10 ? "0" + (current + 1) : current + 1;
    var totalSlidesText = total < 10 ? "0" + total : total;
    $(`.js-slide-count-${key}-${i}`).html(
      `<span class="current-slide"> ${currentSlideText} </span> / ${totalSlidesText}`
    );
  }
}

// Slick Slider with slide-count
function slickSliderWithCounter() {
  if ($(".js-slider-heighlight").length) {
    $(".js-slider-heighlight").each(function (i, e) {
      var $slider = $(this).find(".js-slider-heighlight-wrapper");
      var key = "heighlight";
      $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
      $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
      $slider.slick({
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        appendArrows: `.js-slide-arrow-${key}-${i}`,
        rtl: isRTL,
        draggable: false,
        swipe: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });

      slideCount($slider, i, key);
    });
  }
}

// Slick Slider with slide-count
function managementSlider() {
  if ($(".js-slider-management").length) {
    $(".js-slider-management").each(function (i, e) {
      var $slider = $(this).find(".js-slider-management-wrapper");
      var key = "management";
      $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
      $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
      $slider.slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        appendArrows: `.js-slide-arrow-${key}-${i}`,
        rtl: isRTL,
        draggable: false,
        swipe: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });

      slideCount($slider, i, key);
    });
  }
}

// Slick Slider with slide-count
function partnerSlider() {
  if ($(".js-slider-partner").length) {
    $(".js-slider-partner").each(function (i, e) {
      var $slider = $(this).find(".js-slider-partner-wrapper");
      var key = "partner";
      $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
      $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
      $slider.slick({
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        appendArrows: `.js-slide-arrow-${key}-${i}`,
        rtl: isRTL,
        variableWidth: true,
        draggable: false,
        swipe: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });

      slideCount($slider, i, key);
    });
  }
}

// Slick Slider with slide-count
function testimonialSlider() {
  if ($(".js-slider-testimonial").length) {
    $(".js-slider-testimonial").each(function (i, e) {
      var $slider_for = $(this).find(".testimonial-for");
      var $slider_nav = $(this).find(".testimonial-nav");
      var key = `testimonial`;
      $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
      $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
      $slider_for.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        rtl: isRTL,
        asNavFor: ".testimonial-nav",
        draggable: false,
        swipe: false,
      });
      $slider_nav.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: ".testimonial-for",
        dots: false,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        appendArrows: `.js-slide-arrow-${key}-${i}`,
        draggable: false,
        swipe: false,
        rtl: isRTL,
      });

      slideCount($slider_nav, i, key);
    });
  }
}

// Slick Slider with slide-count
function drawerSlider() {
  if ($(".drawer-wrapper").length) {
    $(".drawer-wrapper").each(function (i, e) {
      var $slider = $(this).find(".js-slider-drawer-wrapper");
      var key = "drawer";
      $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
      $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
      $slider.slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        appendArrows: `.js-slide-arrow-${key}-${i}`,
        rtl: isRTL,
        fade: true,
        draggable: false,
        swipe: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      slideCount($slider, i, key);
    });
  }
}

// On Drawer open Goto Slide
function goToSlide($sliderElement, slideIndex) {
  $sliderElement.slick("slickGoTo", slideIndex);
}

function initSelect2(target) {
  var target = $(target);
  target.each(function (i, e) {
    var tarPar = $(e).parent(".select2-box");
    var tarPlace = $(e).data("placeholder");
    var dataClass = $(e).data("class");
    $(e).select2({
      placeholder: tarPlace,
    });
    // // $(e).on('select2:open', function (e) {
    // // 	setTimeout(function () {
    // // 		formatNumbers();
    // // 	}, 10);
    // // });
    // $(e).on('select2:select select2:unselect', function (e) {
    // 	$(e.target).valid();
    // });
  });
}

// Custom Drawer
function drawer() {
  const $jsDrawers = $(".js-drawer");
  const $jsDrawersClose = $(".js-drawer-close");
  const $jsBody = $("body");
  const $jsSliderElem = $(".js-slider-drawer-wrapper");
  if ($jsDrawers.length) {
    $jsDrawers.click(function () {
      const drawerId = $(this).data("drawer-id");
      const parentElem = $(this).closest(".slick-slide");
      if (parentElem.length) {
        const slideIndex = parentElem.attr("data-slick-index");
        goToSlide($jsSliderElem, slideIndex);
      }
      $(`[data-drawer-id="${drawerId}"]`).each(function () {
        const element = $(this);
        if (element.hasClass("js-drawer-wrapper")) {
          element.addClass("popup--show");
        }
      });

      $jsBody.addClass("overflow-y-hidden");
    });

    $jsDrawersClose.click(function () {
      const drawerId = $(this).data("drawer-id");
      $(`[data-drawer-id="${drawerId}"]`).removeClass("popup--show");
      if (!$(".popup--show")[0]) {
        $jsBody.removeClass("overflow-y-hidden");
      }
    });
    $jsBody.on("click", function (event) {
      const isDrawerOpenBtn = $(event.target).closest(".js-drawer").length > 0;
      const isDrawerConatainer =
        $(event.target).closest(".drawer-contentBox").length > 0;
      if (!isDrawerOpenBtn && !isDrawerConatainer) {
        $(".js-drawer-wrapper").removeClass("popup--show");
        $jsBody.removeClass("overflow-y-hidden");
      }
    });
    ChangeToSvg();
  }
}

// Eeditorial Block Animation
function editorialBlockAnimation() {
  const secEditorialBlock = $(".js-sec-editorial-block");
  const secEditorialBlockWrappers = $(".js-sec-editorial-block-wrapper");
  if (
    secEditorialBlock &&
    secEditorialBlockWrappers &&
    secEditorialBlockWrappers.length > 0
  ) {
    match_Media.add("(min-width: 768px)", () => {
      var editorialBlock_tl = gsap.timeline({
        scrollTrigger: {
          trigger: secEditorialBlock,
          start: "top top",
          end: () =>
            "+=" +
            secEditorialBlock.height() * (secEditorialBlockWrappers.length - 1),
          scrub: 1,
          pin: true,
          snap: {
            snapTo: 1 / (secEditorialBlockWrappers.length - 1),
            inertia: false,
            delay: 0.2,
            ease: "power1.inOut",
            duration: { min: 0.1, max: 0.5 },
          },
        },
      });
      secEditorialBlockWrappers.each((i, elem) => {
        const imageElement = $(elem).find(".js-sec-editorial-block-right");
        const conetntElement = $(elem).find(".sec-editorial-block-left");
        if (i === 0) {
          gsap.set(imageElement, { yPercent: 0, zIndex: 1 });
          gsap.set(conetntElement, { opacity: 1, zIndex: 1 });
          gsap.set(conetntElement, { backgroundColor: "#ffffff" });
        } else {
          gsap.set(imageElement, { zIndex: 1 });
          gsap.set(conetntElement, { opacity: 0, zIndex: 1 });
          editorialBlock_tl
            .fromTo(
              imageElement,
              { yPercent: 100 },
              { yPercent: 0, duration: 0.5 }
            )
            .to(conetntElement, { opacity: 1 }, "-=40%")
            .to(conetntElement, { opacity: 0, duration: 0 }, "<")
            .to(
              conetntElement,
              {
                backgroundColor: i % 2 === 0 ? "#ffffff" : "#ECF5F6",
                duration: 0,
              },
              "<"
            );
        }
      });
      return () => {
        // optional
        // custom cleanup code here (runs when it STOPS matching)
        gsap.set(secEditorialBlock, {
          clearProps: "all",
        });
      };
    });
  }
}

// initialize Isotopes on Media Pages
function isotopes() {
  // init Isotope
  var $grid = $(".grid").isotope({
    itemSelector: ".element-item",
    layoutMode: "fitRows",
    originLeft: !isRTL
  });

  // bind filter button click
  $(".js-filters-btn-group").on("click", "button", function () {
    var filterValue = $(this).attr("data-filter");

    // use filterFn if matches value
    $grid.isotope({ filter: filterValue });
  });

  // change is-checked class on buttons
  $(".js-btn-group").each(function (i, buttonGroup) {
    var $buttonGroup = $(buttonGroup);
    $buttonGroup.on("click", "button", function () {
      $buttonGroup.find(".is-active").removeClass("is-active");
      $(this).addClass("is-active");
    });
  });
}

function searchAnim() {
  $(".search-box .close").hide();
  $(".search-box .close").click(function () {
    $(".search-box .search").removeClass("search--active");
    $(".search-box .close").hide();
    $(".search-box .search").val("");
  });
  $(".search-box .search").click(function () {
    $(".search-box .close").show();
    $(".search-box .search").addClass("search--active");
  });
}

// Intl Tel Input
function intlTelInputInit() {
  const input = document.querySelectorAll(".phone-number-input");
  if (input && input.length > 0) {
    input.forEach((input) => {
      var iti = window.intlTelInput(input, {
        separateDialCode: true,
        initialCountry: "sa",
      });
      window.iti = iti;
    });
  }
}

$.validator.addMethod("validEmail", (value) =>
  /^([A-Za-z0-9_\-\.\%\$\&\'\(\)\*\+/])+\@([A-Za-z0-9_\-\.\%\$\&\'\(\)\*\+/])+\.([a-z]{2,4})$/gi.test(
    value
  )
);

$.validator.addMethod("validPhoneNumber", (value) =>
  /^\d{8,14}$/gi.test(value)
);

$.validator.addMethod("validName", (value) => /^[a-zA-Z\s]+$/.test(value));

$.validator.addMethod("uploadFile", function (value, element) {
  const el = $(element);
  let valid_types = el.data("extension");
  const maxSize = el.data("maxfilesize");
  const size = element.files[0].size;
  const name = element.files[0].name;
  valid_types =
    typeof valid_types === "string"
      ? valid_types.replace(/,/g, "|")
      : "png|jpe?g|doc|docx|pdf";
  // remove spaces if available
  valid_types = valid_types.replace(/ /g, "");
  return (
    name.match(new RegExp(".(" + valid_types + ")$", "i")) && size <= maxSize
  );
});
function validateForm() {
  // Get all the input fields with the class 'form-control'
  const inputs = $(".form-control");
  // Initialize a flag to check if all 'form-control' fields have values
  let isValid = true;
  // Loop through input fields and check if they have values
  inputs.each((_, input) => {
    if (input.value === "") {
      isValid = false;
    }
  });
  // Check the checkboxes
  const checkBox = $('input[type="checkbox"]');
  checkBox.each((_, checkbox) => {
    if (!checkbox.val()) {
      isValid = false;
    }
  });

  if (isValid) {
    return true;
  } else {
    return false;
  }
}

function initValidation(target) {
  if ($(target).length) {
    var $target = $(target);
    var $submitButton = $target.find('button[type="submit"]');
    $submitButton.prop("disabled", true);
    $target.each(function (i, e) {
      var v = $target.eq(i).validate({
        errorElement: "span",
        errorClass: "error-txt body3",
        focusInvalid: false,
        highlight: function (element) {
          $(element).parents(".form-group").addClass("invalid-field");
        },
        unhighlight: function (element) {
          $(element).parents(".form-group").removeClass("invalid-field");
        },
        invalidHandler: function (form, validator, element) {
          var errors = validator.numberOfInvalids();
          if (errors) {
            $(element).parents(".form-group").addClass("invalid-field");

          } else {
            $target.find(".error-msg").hide();
          }
        },
        submitHandler: function (form, validator) {
          event.preventDefault();
          var formData = new FormData(form);

          var dialCode = $(".iti__selected-dial-code").text().trim();
          formData.set("phoneNumber", dialCode + formData.get("phoneNumber"));

          var formDataObject = Object.fromEntries(formData.entries());

          formSubmit(formDataObject);

          $(".js-select2").val(null).trigger("change");

          $submitButton.prop("disabled", true);

          $(form).trigger("reset");

          const charCount = $(form).find(".js-char-count");

          if (charCount) {
            charCount.text(`0/100`);
          }

          const $fileUpload = $(form).find(".js-file-upload");


          if ($fileUpload) {
            $fileUpload.each((i, fileUpload) => {
              const $fileUploadElement = $(fileUpload);
              const $fileTitle = $fileUploadElement.find(
                ".js-attached-file-title"
              );

              const $btnClose = $fileUploadElement.find(".js-btn-close");

              $btnClose.removeClass("show");

              $fileTitle.html(
                "<p class='body4'>Click to Upload or drag and drop</p><p class='body4 req-file'> PDF,PNG,JPG or TIFF (max file size of 3mb)</p>"
              );
            });
          }

          var input = document.querySelector("#phoneNumber");
          if (input) {

            var instance = window.intlTelInputGlobals.getInstance(input);
          }
          instance.setCountry("AE");
        },
        errorPlacement: function (error, element) {
          if (element.attr("type") === "checkbox") {
            // Handle checkbox or intlTelInput error placement as needed
          } else {
            error.insertAfter(element);
          }
        },
      });
    });
    const validateFileUploads = () => {
      const fileUploads = $target.find('input[type="file"]');
      let isValid = true;

      fileUploads.each((_, fileUpload) => {
        if (!$(fileUpload).valid()) {
          isValid = false;
          return false; // to break out of the loop early if one file is not valid
        }
      });

      return isValid;
    };
    // Enable submit button only when all fields are valid

    $target.find("input, select, textarea").on("keyup change", function () {
      $target.find("input, select, textarea").each(function () {
        if (validateForm() && validateFileUploads()) {
          $submitButton.prop("disabled", false);
        } else {
          $submitButton.prop("disabled", true);
        }
      });
    });

  }
}

function messageCharacterCount() {
  const textarea = $(".js-textarea");

  if (textarea.length > 0) {
    function updateCharacterCount() {
      const currentCount = textarea.val().length;

      const charCount = $(".js-char-count");

      if (charCount.length > 0) {
        charCount.text(`${currentCount}/100`);
      }
    }

    textarea.on("keydown keyup", updateCharacterCount);
  }
}

function fileInputClick() {
  $(".js-file-upload").each((i, ele) => {
    const $ele = $(ele);
    $ele.on("change", (event) => {
      const $this = $(event.currentTarget);
      const $fileTitle = $this.find(".js-attached-file-title");
      const $btnClose = $this.find(".js-btn-close");
      const $fileTitleHtml = $fileTitle.html();
      const $inputVal = $this.find('input[type="file"]');

      const val = $inputVal.val();
      const filename = val.replace(/^.*[\\/]/, "");
      if (filename === "") {
        $fileTitle.html(
          "<p class='body4'>Click to Upload or drag and drop</p><p class='body4 req-file'> PDF,PNG,JPG or TIFF (max file size of 3mb)</p>"
        );
      } else {
        $fileTitle.html(`<p class='body4 file-name'>${filename}</p>`);
        $btnClose.addClass("show");
      }
      $inputVal.valid();

    });
  });
}

function clearFileInput() {
  const $btnClose = $(".js-btn-close");

  $btnClose.each((i, ele) => {
    const $ele = $(ele);

    $ele.on("click", (event) => {
      const $this = $(event.currentTarget);
      const $inputVal = $this.siblings(".form-control");
      const $files = $this.siblings(".js-files");

      const $fileTitle = $files.find(".js-attached-file-title");
      $inputVal.val("");

      $inputVal.valid();
      initValidation(".js-contact-form");
      $fileTitle.html(
        "<p class='body4'>Click to Upload or drag and drop</p><p class='body4 req-file'> PDF,PNG,JPG or TIFF (max file size of 3mb)</p>"
      );
      $this.removeClass("show");
    });
  });
}

function sectionAnim() {
  $(".secAnimate").each(function () {
    gsap.fromTo(
      this,
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          duration: 0.5,
          trigger: this,
          start: "top bottom",
          end: "bottom top",
          ease: "power1.inOut",
        },
      }
    );
  });
}

$(document).ready(function () {
	const banner = $('#cookie-banner');
	const acceptButton = $('#accept-cookies');
	if (localStorage.getItem('cookieAccepted')) {
		banner.hide();
	}
	acceptButton.on('click', function () {
		banner.fadeOut(300);
		localStorage.setItem('cookieAccepted', 'true');
	});
});